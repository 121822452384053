import React from 'react'
import { graphql } from 'gatsby'
import { formatTemplateData } from '@utils/templates'
import Container from '@layout/Container'
import FlexComponents from '@layout/FlexComponents'

const NotFoundPage = ({ data }) => {
  // const { wordpressPage } = data

  const title = 'Error 404'
  const heroFields = {
    textContent: {
      heading: title,
    },
    hasSocial: false,
  }

  const template = {
    title,
    heroFields,
    designations: ['NotFoundPage'],
    heroType: 'post',
    theme: 'dark',
  }

  const flexContent = []
  // const flexContent = [
  //   {
  //     layout: 'text',
  //     text: {
  //       textContent: {
  //         selectedFields: ['heading', 'content'],
  //         heading: '',
  //         content: 'Hi there',
  //       },
  //       containerWidth: 'wide',
  //       contentAlign: 'left',
  //     },
  //   },
  // ]

  return (
    <Container template={formatTemplateData(template)}>
      {flexContent &&
        flexContent.map(({ layout, ...componentFields }, index) => {
          return (
            <FlexComponents
              key={`${layout}-${index}`}
              layout={layout}
              componentProps={{ ...componentFields }}
            />
          )
        })}
    </Container>
  )
}

export default NotFoundPage

// export const pageQuery = graphql`
//   query NotFoundPage {
//     wordpressPage {
//       # ...Query
//     }
//   }
// `
